<template>
  <b-card>
    <b-modal
        hide-footer
        id="modal-prevent-move-line"
        ref="my-modal"
        :title="`Operaciones detalladas`"
        cancel-title="Cerrar"
        cancel-variant="outline-secondary"
        @show="resetModal"
        @hidden="resetModal"
        size="lg"
    >
      <b-row>
        <b-overlay :show="show2" no-wrap/>
        <b-col cols="4">
          <b-form-group label="Nombre de número de lote">
            <b-form-input
                disabled
                v-model="sendDataDetails.lot_name"
                placeholder="Nombre de número de lote"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Fecha de caducidad">
            <b-form-input
                disabled
                v-model="sendDataDetails.expiration_date"
                placeholder="Fecha de caducidad"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Hecho">
            <b-form-input
                disabled
                v-model="sendDataDetails.qty_done"
                placeholder="Hecho"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Laboratorio">
            <b-form-input
                disabled
                v-model="sendDataDetails.laboratorio"
                placeholder="Laboratorio"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <div class="m-2">
      <b-overlay :show="show" no-wrap/>
      <b-row>
        <b-col lg="12">
          <b-form @submit.prevent="sendInventory(send)">
            <b-row>
              <b-col cols="12" style="margin-bottom: 30px">
                <h3>Rerencia: <strong>{{reference}}</strong></h3><br>
                <h3>Estatus: <strong>{{changeTextSpanishSingleInventory(stateAll)}}</strong></h3>

              </b-col>
              <b-col cols="6">
                <b-form-group label="Fecha de transaccion">
                  <flat-pickr
                      v-model="scheduled_date"
                      class="form-control"
                      disabled=""
                      :config="{
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i:m',
                      locale: {
                        firstDayOfWeek: 1,
                        weekdays: {
                          shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
                          longhand: [
                            'Domingo',
                            'Lunes',
                            'Martes',
                            'Miércoles',
                            'Jueves',
                            'Viernes',
                            'Sábado',
                          ],
                        },
                        months: {
                          shorthand: [
                            'Ene',
                            'Feb',
                            'Mar',
                            'Abr',
                            'May',
                            'Jun',
                            'Jul',
                            'Ago',
                            'Sep',
                            'Оct',
                            'Nov',
                            'Dic',
                          ],
                          longhand: [
                            'Enero',
                            'Febreo',
                            'Мarzo',
                            'Abril',
                            'Mayo',
                            'Junio',
                            'Julio',
                            'Agosto',
                            'Septiembre',
                            'Octubre',
                            'Noviembre',
                            'Diciembre',
                          ],
                        },
                      },
                    }"
                  />

                </b-form-group>
                <b-form-group label="Proveedor">
                  <v-select
                      disabled
                      style="border-radius: 1px !important"
                      v-model="selectPartner"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="value"
                      @input="searchByPartner()"
                      :options="optionsPartners"
                  />
                </b-form-group>
                <b-form-group label="Documento de origen">
                  <b-form-input
                      disabled
                      v-model="send.origin"
                      placeholder="Documento de origen"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" lg="6" class="mt-1">
                <b-row>
                  <b-col cols="12" lg="6">

                  </b-col>

                  <b-col cols="12" lg="6">
                    <div align="right">
                      <b-button
                          @click="cancelUpdate()"
                          style="width: 100%; margin-top: 10px"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="button"
                          variant="primary"
                          class="mr-1"
                      >
                        Regresar
                      </b-button>

                    </div>

                  </b-col>
                </b-row>
              </b-col>

              <b-col cols="12" class="mt-3">
                <table>
                  <tr>
                    <th>Producto</th>
                    <th>Unidad de medida</th>
                    <th>Acciones</th>
                  </tr>
                  <tr v-for="(p, idx) in new_lines" :key="idx">
                    <td>
                      <v-select
                          style="width: 100%"
                          disabled
                          v-model="p.name"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="value"
                          :options="product"
                      />
                    </td>
                    <td>
                      <b-form-group label="Unidad de medida" label-for="Nombre">
                        <b-form-input
                            style="display: none"
                            disabled
                            v-model="p.product_id"
                            placeholder="Producto id"
                        />
                        <b-form-input
                            style="display: none"
                            disabled
                            v-model="p.uom"
                            placeholder="Unidad id"
                        />
                        <b-form-input
                            style="width: 200px"
                            disabled
                            v-model="p.uomName"
                            placeholder="Unidad de medida"
                        />
                      </b-form-group>
                    </td>
                    <td>
                      <b-row>
                        <b-col cols="12" v-if="!p.new_line">
                          <b-button
                              @click="addRow(idx)"
                              style="width: 100%"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              type="button"
                              variant="primary"
                          >
                            Ver
                          </b-button>
                        </b-col>
                        <b-col cols="12" v-else>
                          <b-button
                              @click="openAssigned(idx)"
                              style="width: 100%"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              type="button"
                              variant="primary"
                          >
                           Ver detalles
                          </b-button>

                        </b-col>
                      </b-row>
                    </td>
                  </tr>
                </table>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  VBTooltip,
  BOverlay,
  BModal,
  VBModal,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import {mapActions, mapGetters} from "vuex";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {VueGoodTable} from "vue-good-table";
import ModalAppointment from "@/components/Appointments/ModalAppointment";
import flatPickr from "vue-flatpickr-component";
import {QrcodeStream} from "vue-qrcode-reader";

export default {
  name: "UpdateInventory",
  components: {
    VueGoodTable,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BOverlay,
    VBTooltip,
    BModal,
    VBModal,
    ModalAppointment,
    flatPickr,
    BFormCheckbox,
    BFormCheckboxGroup,
    vSelect,
    BForm,
    QrcodeStream,
  },
  data() {
    return {
      show: false,
      show2: false,
      resulScan: null,
      selectPartner: {id: "", value: "Seleccione a un proveedor"},
      optionsPartners: [],
      optionsPartnersAll: [],
      id: null,
      error: null,
      stateAll: null,
      scheduled_date: null,
      openScan: null,
      sendDataDetails: {
        id: null,
        lot_name: null,
        laboratorio: null,
        expiration_date: null,
        qty_done: 1,
      },
      sendNewLine: null,
      reference: null,
      positionScan: null,
      linesMove: true,
      product: [],
      productAll: [],
      new_lines: [],
      send: {
        partner_id: null,
        origin: null,
      },
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
    "b-modal": VBModal,
  },
  async created() {
    this.id = this.$route.params.id;

    await this.findOneInventorySearch(this.id);
    await this.findPartners();
    await this.findProducts();
  },
  methods: {
    ...mapActions("inventory", [
      "findListPartners",
      "findOneInventory",
      "updateAll",
      "createMoveLine",
      "createMoveLines",
      "verifyMoveLine",
      "validateStock",
      "validateReady",
      "deleteLine",
    ]),
    ...mapActions("treatment", ["findProduct"]),
    async cancelUpdate(){
      await this.$router.push('/lista_entradas')
    },
    async deleteLineData(id) {
      if (this.stateAll === "draft") {
        this.show = true;
        const response = await this.deleteLine(id);
        if (response.msg) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Se elimino la linea correctamente",
              icon: "edit",
              variant: "success",
            },
          });
          this.show = false;
          this.sendDataDetails.lot_name = null;
          this.sendDataDetails.expiration_date = null;
          this.sendDataDetails.qty_done = null;
          this.sendDataDetails.laboratorio = null;
          this.sendDataDetails.id = null;
          await this.findOneInventorySearch(this.id);
          await this.findPartners();
          await this.findProducts();
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error,
              icon: "error",
              variant: "warning",
            },
          });
          this.show = false;
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Sólo puede eliminar movimientos borrador.",
            icon: "error",
            variant: "warning",
          },
        });
      }
    },
    async validateDataStock() {
      this.show = true;
      const response = await this.validateStock(this.id);
      if (response.msg) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Se valido correctamente",
            icon: "edit",
            variant: "success",
          },
        });
        this.show = false;
        this.sendDataDetails.lot_name = null;
        this.sendDataDetails.expiration_date = null;
        this.sendDataDetails.qty_done = null;
        this.sendDataDetails.laboratorio = null;
        this.sendDataDetails.id = null;
        await this.findOneInventorySearch(this.id);
        await this.findPartners();
        await this.findProducts();
        await this.$router.push('/entradas_productos')
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error,
            icon: "error",
            variant: "warning",
          },
        });
        this.show = false;
      }
    },
    async validateSuccess() {
      this.show = true;
      this.$swal({
        title: 'Proceso',
        text: `Deseas procesar esta orden ya no se podrán añadir líneas`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si continuar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          const response = await this.validateReady(this.id);
          if (response.msg) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Se valido correctamente",
                icon: "edit",
                variant: "success",
              },
            });
            this.show = false;
            this.sendDataDetails.lot_name = null;
            this.sendDataDetails.expiration_date = null;
            this.sendDataDetails.qty_done = null;
            this.sendDataDetails.laboratorio = null;
            this.sendDataDetails.id = null;
            await this.findOneInventorySearch(this.id);
            await this.findPartners();
            await this.findProducts();
            this.show = false
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.error,
                icon: "error",
                variant: "warning",
              },
            });
            this.show = false
          }


        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelado',
            text: 'Se cancelo el proceso',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          this.show = false
        }
      })
    },
    onDecode(result) {
      if (result) {
        this.resulScan = JSON.parse(result);
      }
    },
    async saveNewLine() {
      const searchId = this.productAll.find(
          (a) => a.id === this.resulScan.id_product
      );

      this.new_lines[this.positionScan].product_id = searchId.id;
      this.new_lines[this.positionScan].name = searchId.name;
      this.new_lines[this.positionScan].uom = searchId.uom_id;
      this.new_lines[this.positionScan].uomName = searchId.UomUom.name;
      this.new_lines[this.positionScan].consecutive =
          this.resulScan.consecutive;
      this.new_lines[this.positionScan].lots = this.resulScan.lots;
      this.new_lines[this.positionScan].date_expiry =
          this.resulScan.date_expiry;
      this.new_lines[this.positionScan].barcode = this.resulScan.barcode;
      this.positionScan = null;
      this.openScan = null;
      this.error = null;
      this.resulScan = null;

      await this.$bvModal.hide("modal-prevent-closing-scan");
    },
    async scanQr() {
      this.openScan = true;
    },

    async newScanQr() {
      this.openScan = null;
      this.error = null;
      this.resulScan = null;
    },
    async openModal(id) {
      if (this.stateAll === 'done') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Ya no puede agregar otro producto porque ya tiene una salida",
            icon: "error",
            variant: "warning",
          },
        });
        return
      }
      if (this.stateAll === 'assigned') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Ya no puede agregar otro producto porque esta en estatus listo",
            icon: "error",
            variant: "warning",
          },
        });
        return
      }
      if (this.new_lines[id].id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Esta linea ya tiene un qr scanneado",
            icon: "error",
            variant: "warning",
          },
        });
        return;
      }
      this.positionScan = null;
      this.openScan = null;
      this.error = null;
      this.resulScan = null;
      this.positionScan = id;
      await this.$bvModal.show("modal-prevent-closing-scan");
    },
    async openAssigned(id) {

        this.sendDataDetails.lot_name = null;
        this.sendDataDetails.expiration_date = null;
        this.sendDataDetails.qty_done = 1;
        this.sendDataDetails.laboratorio = null;
        this.sendDataDetails.id = null;
        this.positionScan = null;
        this.openScan = null;
        this.error = null;
        this.resulScan = null;
        const response = await this.verifyMoveLine({
          move_id: this.new_lines[id].id,
          picking_id: this.id,
        });
        if (response.line) {
          this.sendDataDetails.lot_name = response.line.lot_name;
          this.sendDataDetails.expiration_date = response.line.expiration_date;
          this.sendDataDetails.qty_done = response.line.qty_done;
          this.sendDataDetails.laboratorio = response.line.laboratorio;
          this.sendDataDetails.id = response.line.id;
        } else {
          this.sendDataDetails.lot_name = this.new_lines[id].lots;
          this.sendDataDetails.expiration_date = this.new_lines[id].date_expiry;
        }
        this.positionScan = id;
        await this.$bvModal.show("modal-prevent-move-line");
    },
    async createLinesMove() {
      const sendLine = this.new_lines[this.positionScan];

      sendLine.picking_id = parseInt(this.id);
      sendLine.expiration_date = this.sendDataDetails.expiration_date;
      sendLine.laboratorio = this.sendDataDetails.laboratorio;
      sendLine.lot_name = this.sendDataDetails.lot_name;
      sendLine.qty_done = this.sendDataDetails.qty_done;
      sendLine.id = this.sendDataDetails.id;
      if (!sendLine.expiration_date) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Fecha de expiracion requerida",
            icon: "error",
            variant: "warning",
          },
        });
        return;
      }
      if (!sendLine.laboratorio) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Laboratorio requerido",
            icon: "error",
            variant: "warning",
          },
        });
        return;
      }
      if (!sendLine.lot_name) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Lote requerido",
            icon: "error",
            variant: "warning",
          },
        });
        return;
      }
      if (!sendLine.qty_done) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Hecho requerido",
            icon: "error",
            variant: "warning",
          },
        });
        return;
      }
      if (!sendLine.picking_id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Id requerido",
            icon: "error",
            variant: "warning",
          },
        });
        return;
      }
      this.show2 = true;
      const response = await this.createMoveLines(sendLine);
      if (response.msg) {
        if (this.sendDataDetails.id) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Se edito la linea correctamente",
              icon: "edit",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Se creo la linea correctamente",
              icon: "edit",
              variant: "success",
            },
          });
        }
        this.show2 = false;
        this.sendDataDetails.lot_name = null;
        this.sendDataDetails.expiration_date = null;
        this.sendDataDetails.qty_done = null;
        this.sendDataDetails.laboratorio = null;
        this.sendDataDetails.id = null;
        await this.$bvModal.hide("modal-prevent-move-line");
        await this.findOneInventorySearch(this.id);
        await this.findPartners();
        await this.findProducts();
      } else {
        this.show2 = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error,
            icon: "error",
            variant: "warning",
          },
        });
        return;
      }
    },
    async addRow(id) {
      const sendLine = this.new_lines[id];
      sendLine.picking_id = parseInt(this.id);
      if (!sendLine.product_id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Producto en la linea requerido",
            icon: "error",
            variant: "warning",
          },
        });
        return;
      }
      if (!sendLine.uom) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Unidad de medida en la linea requerido",
            icon: "error",
            variant: "warning",
          },
        });
        return;
      }
      if (!sendLine.picking_id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Id en la linea requerido",
            icon: "error",
            variant: "warning",
          },
        });
        return;
      }
      this.show = true;
      const response = await this.createMoveLine(sendLine);

      if (response.msg) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Se creo la linea correctamente",
            icon: "edit",
            variant: "success",
          },
        });
        this.show = false;
        await this.findOneInventorySearch(this.id);
        await this.findPartners();
        await this.findProducts();
      } else {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error,
            icon: "error",
            variant: "warning",
          },
        });
      }
    },
    async findOneInventorySearch(firstCharge = true) {
      this.new_lines = [];
      if (!firstCharge) {
        const searchId = this.optionsPartnersAll.find(
            (a) => a.id === this.send.product_id
        );
        this.selectPartner.id = this.send.product_id;
        this.selectPartner.value = searchId.name;
      }
      const response = await this.findOneInventory(this.id);
      if (response.state !== 'done'){
        await this.$router.push('/entradas_productos')
        return
      }
      this.scheduled_date = this.resHoursOdoo(response.scheduled_date)
      this.send = {
        partner_id: response.partner_id,
        origin: response.origin,
      };
      this.stateAll = response.state;
      this.reference = response.name;

      if (response.StockMoves.length > 0) {
        this.linesMove = false;
        for (const a of response.StockMoves) {
          this.new_lines.push({
            id: a.id,
            product_id: a.product_id,
            name: a.name + " " + `(${a.ProductTemplate.default_code})`,
            uom: a.product_uom,
            uomName: a.UomUom.name,
            consecutive: a.MedicationMoveLine.consecutive,
            lots: a.MedicationMoveLine.lots,
            date_expiry: a.MedicationMoveLine.date_expiry,
            barcode: a.MedicationMoveLine.barcode,
            new_line: true,
          });
        }
      }

    },
    async findProducts() {
      this.productAll = [];
      this.product = [];
      const response = await this.findProduct();

      this.productAll = response;
      for (const a of response) {
        const de = a.default_code ? "(" + a.default_code + ")" : "";
        this.product.push({
          id: a.id,
          value: a.name + " " + de,
        });
      }
    },
    async verifyData() {
      if (!this.send.partner_id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Campo Proveedor es requerido",
            icon: "error",
            variant: "warning",
          },
        });
        return false;
      }
      if (!this.send.origin) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Campo documento de origen es requerido",
            icon: "error",
            variant: "warning",
          },
        });
        return false;
      }

      return true;
    },
    async sendInventory(data) {
      const verifyDataResponse = await this.verifyData();
      if (!verifyDataResponse) return;
      const dataSend = {
        partner_id: this.send.partner_id,
        origin: this.send.origin,
      };

      const response = await this.updateAll({id: this.id, data: dataSend});
      if (response.msg) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Se edito correctamente",
            icon: "EditIcon",
            variant: "success",
          },
        });
        await this.findOneInventorySearch();
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error al editar contacte con el administrador",
            icon: "error",
            variant: "warning",
          },
        });
      }
    },
    async clearDate() {
      this.send.scheduled_date = null;
    },
    async searchByPartner() {
      this.send.partner_id = this.selectPartner ? this.selectPartner.id : null;
    },
    async resetModal() {
    },
    async findPartners() {
      this.optionsPartners = [];
      this.optionsPartnersAll = [];
      const response = await this.findListPartners();

      for (const a of response.partners) {
        if (a.id === this.send.partner_id) {
          this.selectPartner.id = this.send.partner_id;
          this.selectPartner.value = a.name;
        }
        this.optionsPartners.push({
          id: a.id,
          value: a.name,
        });
        this.optionsPartnersAll.push({
          id: a.id,
          name: a.name,
        });
      }
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: necesita otorgar permiso de acceso a la cámara";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no hay cámara en este dispositivo";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: Se requiere contexto seguro (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: ¿La cámara ya está en uso?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: las cámaras instaladas no son adecuadas";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API no es compatible con este navegador";
        } else if (error.name === "InsecureContextError") {
          this.error =
              "ERROR: El acceso a la cámara solo está permitido en un contexto seguro. Utilice HTTPS o localhost en lugar de HTTP.";
        } else {
          this.error = `ERROR: Camera error (${error.name})`;
        }
      }
    },
  },
};
</script>

<style scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
}

.error {
  font-weight: bold;
  font-size: 20px;
  color: red;
}
</style>
